import React, { Component } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { DATEPICKERMONTHS } from 'src/helpers/GlobalConstants';

interface IProps {
    params: ReactDatePickerCustomHeaderProps;
    dateMode: string | null;
}
interface IState {}

export default class CustomHeader extends Component<IProps, IState> {
    state: IState = {};
    render() {
        const {
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        } = this.props.params;

        const currentYear = DateTimeFormatHelper.getYear();
        let startYear = currentYear;
        let endYear = currentYear;

        if (this.props.dateMode === 'lower' || this.props.dateMode === 'both') {
            startYear -= 100;
        }

        if (this.props.dateMode === 'upper' || this.props.dateMode === 'both') {
            endYear += 100;
        }

        if (this.props.dateMode === '') {
            startYear = 1901;
        }

        const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
        const months = DATEPICKERMONTHS;

        return (
            <div className='common-datepicker-header'>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        decreaseMonth();
                    }}
                    disabled={prevMonthButtonDisabled}
                >
                    {'<'}
                </button>
                <div className='year-selection'>
                    <select
                        value={DateTimeFormatHelper.getYear(date)}
                        onChange={({ target: { value } }) => changeYear(parseInt(value))}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='month-selection'>
                    <select
                        value={months[DateTimeFormatHelper.getMonth(date)]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                <button
                    onClick={(e) => {
                        e.preventDefault();
                        increaseMonth();
                    }}
                    disabled={nextMonthButtonDisabled}
                >
                    {'>'}
                </button>
            </div>
        );
    }
}
