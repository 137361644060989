import React, { Component } from 'react';
import apiFile from 'src/helpers/apiFile';
import LessonContext from '../../../../LessonContext';
import { commonTimeout } from '../../../../../../../../helpers/commonTimeout';
import { delay } from 'lodash';
import { EventBus } from 'src/helpers/new';

export interface IProps {
    heading?: string;
    subHeading?: string;
    transcript?: string;
    content?: string;
    sourceAudio?: string;
    theme?: string;
}

interface IState {
    fileUrl: string;
    isPlaying: boolean;
    volume: number;
    currentTime: number;
    duration: number;
    isMuted: boolean;
    isShowIndicationTime: boolean;
}

export default class AudioPage extends Component<IProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;

    state = {
        fileUrl: '',
        isPlaying: false,
        volume: 1,
        currentTime: 0,
        duration: 0,
        isMuted: false,
        isShowIndicationTime: false,
    };

    track = null;
    audioContext = null;
    audioRef: any = React.createRef();

    componentDidMount() {
        this.loadAudioFile();
    }

    componentWillUnmount() {
        this.clearInactiveTimer(false);
        EventBus.dispatch('resume-on-media-stop');
    }

    startInactiveTimer = () => {
        this.context.clearMediaPlay(false);
        this.context.inactiveTimerCallback();
    };

    clearInactiveTimer = (state: boolean) => {
        commonTimeout.clearTimer('inactive');
        commonTimeout.clearTimer('logout');
        this.context.clearMediaPlay(state);
    };

    loadAudioFile = async () => {
        const { sourceAudio } = this.props;
        const { url } = await apiFile(sourceAudio);
        this.setState(
            {
                fileUrl: url,
            },
            () => {
                this.audioRef.current.volume = 1;
            },
        );
    };

    handlePlayButonClick = () => {
        const { isPlaying } = this.state;

        if (!isPlaying) {
            this.setState(
                {
                    isPlaying: true,
                },
                () => {
                    this.audioRef.current.play();
                    EventBus.dispatch('resume-on-media-stop');
                    this.clearInactiveTimer(true);
                },
            );
        } else {
            this.setState(
                {
                    isPlaying: false,
                },
                () => {
                    this.audioRef.current.pause();
                    EventBus.dispatch('pause-on-media-stop');
                    this.startInactiveTimer();
                },
            );
        }
    };

    handleVolumeButtonClick = () => {
        const { isMuted } = this.state;

        if (!isMuted) {
            this.setState(
                {
                    isMuted: true,
                },
                () => {
                    this.audioRef.current.muted = true;
                },
            );
        } else {
            this.setState(
                {
                    isMuted: false,
                },
                () => {
                    this.audioRef.current.muted = false;
                },
            );
        }
    };

    handleMetaDataLoaded = () => {
        this.setState({
            duration: this.audioRef.current.duration,
        });
    };

    handleTimeUpdated = (e: any) => {
        this.setState({
            currentTime: this.audioRef.current.currentTime,
        });
    };

    handlePlaybackEnded = (e: any) => {
        this.setState({
            isPlaying: false,
        });

        this.clearInactiveTimer(false);
        delay(() => {
            this.startInactiveTimer();
        }, 100);
    };

    handleTrackClick = (e: any) => {
        const rect = e.target.getBoundingClientRect(),
            { duration } = this.state,
            trackClickPositionPercentage = ((e.clientX - rect.left) / 210) * 100;

        this.setState(
            {
                currentTime:
                    duration * ((trackClickPositionPercentage > 99 ? 100 : trackClickPositionPercentage) / 100),
            },
            () => {
                const { currentTime } = this.state;

                this.audioRef.current.currentTime = currentTime;
            },
        );
    };

    handleVolumeTrackClick = (e: any) => {
        const rect = e.target.getBoundingClientRect(),
            trackClickPositionPercentage = (e.clientX - rect.left) / 80;

        const volume = Math.min(1, Math.max(0, trackClickPositionPercentage));

        this.setState({ volume }, () => {
            this.audioRef.current.volume = volume;
        });
    };

    secondsToTimeStamp = (seconds: number) => {
        return isNaN(seconds) ? '' : new Date(seconds * 1000).toISOString().substr(14, 5);
    };

    get volumeLevelIcon() {
        const { isMuted, volume } = this.state;
        if (isMuted) {
            return 'fa-volume-mute';
        }

        if (volume === 0) {
            return 'fa-volume-off';
        }

        if (volume < 0.5) {
            return 'fa-volume-low';
        }

        return 'fa-volume-high';
    }

    handleMoveForward = () => {
        if (this.state.currentTime <= this.state.duration) {
            this.setState(
                {
                    currentTime: this.state.currentTime + 15,
                },
                () => {
                    if (this.audioRef.current) {
                        this.audioRef.current.currentTime += 15;
                    }
                },
            );
        }
    };

    handleMoveBackward = () => {
        this.setState(
            {
                currentTime: this.state.currentTime - 15,
            },
            () => {
                if (this.audioRef.current) {
                    this.audioRef.current.currentTime -= 15;
                }
            },
        );
    };

    onMouseDown = () => {
        const progressTime = document.getElementById('tooltip-audio-indicator');
        if (progressTime) {
            this.setState({ isShowIndicationTime: false });
            progressTime.style.left = `${0}%`;
            progressTime.innerText = '00:00';
        }
    };

    onHoverTrack = (e: any) => {
        const rect = e.target.getBoundingClientRect(),
            { duration } = this.state,
            trackClickPositionPercentage = ((e.clientX - rect.left) / 210) * 100;

        const showHoverTime =
            duration * ((trackClickPositionPercentage > 99 ? 100 : trackClickPositionPercentage) / 100);

        if (showHoverTime <= 0) return;
        const progressTime = document.getElementById('tooltip-audio-indicator');
        const timelineWidth = document.getElementById('track')?.clientWidth;
        if (timelineWidth) {
            if (progressTime) {
                progressTime.style.left = `${Math.round(trackClickPositionPercentage)}%`;
                progressTime.innerText = this.secondsToTimeStamp(showHoverTime);
            }
            if (!this.state.isShowIndicationTime && Math.floor(showHoverTime) > 0) {
                this.setState({ isShowIndicationTime: true });
            }
        }
    };

    onMouseEnter = () => {
        this.setState({ isShowIndicationTime: true });
    };

    displayAudioPlayControls = () => {
        const { isPlaying, isMuted, volume, currentTime, duration, isShowIndicationTime } = this.state,
            { theme } = this.props;

        return (
            <div className={`player ${theme === 'navy' ? 'white-controls' : ''} `}>
                <div className='body'>
                    <div
                        className='track'
                        id='track'
                        onClick={this.handleTrackClick}
                        onMouseMove={this.onHoverTrack}
                        onMouseLeave={this.onMouseDown}
                        onMouseEnter={this.onMouseEnter}
                    >
                        {isShowIndicationTime && <span id='tooltip-audio-indicator'>00:00</span>}
                        <div
                            className={`${theme === 'navy' ? 'white-controls' : ''}`}
                            style={{ width: `${(currentTime * 100) / duration}%` }}
                        />
                    </div>
                    <div className='details'>
                        <div className='time'>
                            {this.secondsToTimeStamp(currentTime)} / {this.secondsToTimeStamp(duration)}
                        </div>
                        <div className='volume'>
                            <i className={`fa-solid ${this.volumeLevelIcon}`} onClick={this.handleVolumeButtonClick} />
                            <div onClick={this.handleVolumeTrackClick}>
                                <div
                                    className={`${theme === 'navy' ? 'white-controls' : ''}`}
                                    style={{ width: `${isMuted ? 0 : volume * 100}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className='audio-controls'>
                        <div className='audio-forword-arrow' onClick={this.handleMoveBackward}>
                            <div className='audio-content'>15</div>
                        </div>
                        <i
                            className={`play-pause fa-solid fa-${!isPlaying ? 'play' : 'pause'}`}
                            onClick={this.handlePlayButonClick}
                        ></i>

                        <div className='audio-backword-arrow' onClick={this.handleMoveForward}>
                            <div className='audio-content'>15</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { heading, subHeading, transcript, content } = this.props;

        return (
            <>
                <audio
                    controls={false}
                    src={this.state.fileUrl}
                    ref={this.audioRef}
                    preload='metadata'
                    onDurationChange={this.handleMetaDataLoaded}
                    onTimeUpdate={this.handleTimeUpdated}
                    onEnded={this.handlePlaybackEnded}
                />
                <header>
                    {this.state.fileUrl && this.displayAudioPlayControls()}
                    {(heading || subHeading) && (
                        <div className='heading'>
                            {heading && <h1>{heading}</h1>}
                            {subHeading && <h2>{subHeading}</h2>}
                        </div>
                    )}
                </header>
                {transcript && <div className='transcript' dangerouslySetInnerHTML={{ __html: transcript }} />}
                {content && !transcript && <div className='content' dangerouslySetInnerHTML={{ __html: content }} />}
            </>
        );
    }
}
