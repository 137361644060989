import React, { Component } from 'react';
import './ResizableTextArea.scss';
import { isNil } from 'lodash';

interface IProps {
    value: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    classsName?: string;
    id?: string;
    placeholder?: string;
    onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    readOnly?: boolean;
    onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

interface IState {}
export default class ResizableTextArea extends Component<IProps, IState> {
    textAreaInputRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
    state: IState = {};

    componentDidMount(): void {
        if (this.textAreaInputRef.current) {
            const textarea = this.textAreaInputRef.current;

            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 2 + 'px';

            if (parseInt(textarea.style.height.replace('px', '')) >= 126) {
                textarea.style.overflow = 'auto';
                textarea.style.height = Math.min(Math.max(textarea.scrollHeight, 42), 126) + 2 + 'px';
            } else {
                textarea.style.overflow = 'hidden';
            }
        }
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange(e);
    };

    handleTitleTextResize = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        const minHeight = 42;
        const maxHeight = 126;

        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 2 + 'px';

        if (parseInt(textarea.style.height.replace('px', '')) >= maxHeight) {
            textarea.style.overflow = 'auto';
            textarea.style.height = Math.min(Math.max(textarea.scrollHeight, minHeight), maxHeight) + 2 + 'px';
        } else {
            textarea.style.overflow = 'hidden';
        }
    };

    render() {
        const { value, name, id, placeholder, readOnly } = this.props;
        return (
            <textarea
                ref={this.textAreaInputRef}
                className={`resizable-textarea-input ${this.props.classsName}`}
                name={name}
                value={value}
                id={id ?? name}
                onChange={this.handleInputChange}
                onInput={this.handleTitleTextResize}
                placeholder={placeholder ?? ''}
                readOnly={isNil(readOnly) ? false : readOnly}
                rows={1}
                onClick={this.props.onClick}
                onKeyPress={this.props.onKeyPress}
            />
        );
    }
}
