import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Api, EventBus } from 'src/helpers/new';
import './ChangePasswordPrompt.scss';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import { RouteComponentProps } from 'src/hoc/withRouter';

interface IProps extends RouteComponentProps {
    onChangePassowrd: (closeModal?: boolean) => void;
    isShow: boolean;
    setLoggedIn: (payload: any) => void;
    loggedIn: {
        APP_VERSION: string;
        token: string;
        user: {
            adminLevel: number;
            firstName: string;
            passwordExpiredAt: string;
            timeZone: string;
            userRole: string;
            _id: string;
        };
    };
}

interface IState {
    passwordError: string;
    password: string;
    passwordCheck: string;
}

class ChangePasswordPrompt extends Component<IProps, IState> {
    state: IState = {
        passwordError: '',

        password: '',
        passwordCheck: '',
    };

    handlePasswordChange = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const { response, success, message } = await Api.call('POST', '/users/password/change', {
            password: this.state.password,
            passwordCheck: this.state.passwordCheck,
            isAfterCheckout: true,
        });

        if (success) {
            EventBus.dispatch('toast', {
                type: 'success',
                message: message ?? 'Your password was changed!',
            });

            this.props.setLoggedIn({ ...response, token: this.props.loggedIn?.token });

            this.props.onChangePassowrd(true);
        } else {
            this.setState({
                passwordError: message,
            });
        }
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const input = e.target;

        this.setState((prevState) => ({
            ...prevState,
            [input.name]: input.value,
        }));
    };

    render() {
        const { passwordError } = this.state;

        return (
            <div>
                <Modal className='change-password-model' show={this.props.isShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set a new password to keep your account secure</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className={'form' + (passwordError ? ' form--with-error' : '')}>
                            {passwordError && <div className='form__error'>{passwordError}</div>}
                            <div className='form__content'>
                                <div className='form__field'>
                                    <label htmlFor='password'>New password</label>
                                    <input type='password' name='password' onChange={this.handleInputChange} />
                                </div>
                                <div className='form__field'>
                                    <label htmlFor='passwordCheck'>Repeat new password</label>
                                    <input type='password' name='passwordCheck' onChange={this.handleInputChange} />
                                </div>
                                <div className='form__buttons'>
                                    <button className='btn bp' onClick={this.handlePasswordChange}>
                                        Submit
                                    </button>
                                    <button
                                        className='btn bd'
                                        onClick={() => {
                                            this.props.onChangePassowrd();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withRouterAndRedux(
    ChangePasswordPrompt,
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: any) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    },
);
