import React, { Component } from 'react';
import { Button, Col, Popover, Row, Spinner } from 'react-bootstrap';
import MessageContainer, { ChatMessage } from './MessageContainer';
import ResizableTextArea from 'src/components/inputs/ResizableTextArea';
import { RouteComponentProps } from 'src/hoc/withRouter';
import { clone, debounce, flatMap, groupBy, isEmpty, isNil } from 'lodash';
import { ICourseChat } from 'src/pages/Course/CourseManager';
import { Api, EventBus } from 'src/helpers/new';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { Spinner as APISpinner } from 'src/components/Spinner';
import { IUserLoggedIn } from '../Menus/ProfilePopOver';
import './ChatPopOver.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { sendMessageKeyDown } from 'src/helpers/chatHelper';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import * as Sentry from '@sentry/react';

interface GroupedMessages {
    [key: string]: ChatMessage[];
}
interface IProps extends RouteComponentProps {
    loggedIn: IUserLoggedIn;
    toggleChatMenu: () => void;
    setCourseChat: (payload: any) => void;
    course: any;
    courseChat: ICourseChat;
    isShowChatMenu: boolean;
}
interface IState {
    sendMessage: string;
    isLoading: boolean;
    chatMessageList: Record<string, ChatMessage[]>;
    page: number;
    isMoreLoading: boolean;
    totalPages: number;
    isBottomCancelScroll: boolean;
}

class ChatPopOver extends Component<IProps, IState> {
    private containerRef: React.RefObject<any> = React.createRef();
    state: IState = {
        sendMessage: '',
        isLoading: false,
        chatMessageList: {},
        page: 1,
        isMoreLoading: false,
        totalPages: 0,
        isBottomCancelScroll: false,
    };

    async componentDidMount() {
        if (this.props.courseChat?.conversationId) {
            await this.loadUpdatedConversation(this.props.courseChat?.conversationId);
        }
        EventBus.on('message-from-admin', this.receiveMessageFromAdmin);
    }

    componentWillUnmount(): void {
        if (this.props.courseChat?.conversationId && window.chatsocket) {
            window.chatsocket?.emit('leave-room', { conversationId: this.props.courseChat?.conversationId });
        }
        if (this.props.isShowChatMenu) {
            this.props.toggleChatMenu();
        }
        EventBus.remove('message-from-admin', this.receiveMessageFromAdmin);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (
            this.containerRef?.current &&
            prevProps.courseChat?.conversationId &&
            this.props.courseChat?.conversationId === prevProps.courseChat?.conversationId &&
            !this.state.isBottomCancelScroll
        ) {
            const scrollOptions = { behavior: 'smooth', block: 'end', inline: 'nearest' };
            this.containerRef?.current?.scrollIntoView(scrollOptions);
        }
    }

    loadUpdatedConversation = async (conversationId: string) => {
        try {
            if (conversationId) {
                this.setState({ isLoading: true });
                window.chatsocket?.emit('join-room', { conversationId: conversationId });
                window.chatsocket?.on(conversationId, (data) => {
                    if (data?.newMessage) {
                        this.updateMessageList(this.state.chatMessageList, data.newMessage);
                    }
                });
                await this.loadConversationData(conversationId);
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    receiveMessageFromAdmin = async (event: any) => {
        try {
            const { conversationId } = event.detail;
            await this.loadUpdatedConversation(conversationId);
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    loadConversationData = async (conversationId: string) => {
        try {
            const { success, response } = await Api.chatCall('GET', `/messages/${conversationId}`);
            if (success) {
                const messagesData = [...response.docs].reverse();
                const groupMessage: GroupedMessages = this.groupMessages(messagesData);
                this.setState({ chatMessageList: groupMessage, isLoading: false, totalPages: response?.totalPages });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    groupMessages = (messagesData: ChatMessage[]) => {
        return groupBy(messagesData, (val: ChatMessage) => {
            return DateTimeFormatHelper.format(val.timestamp, 'MM/DD/YYYY');
        });
    };

    onHandleSendMessage = (event: any) => {
        this.preventParentEvent(event);
        this.setState({ sendMessage: event.target.value, isBottomCancelScroll: true });
    };

    sendMessageToInstructor = (event: any) => {
        this.preventParentEvent(event);
        if (!isNil(this.props.course?.courseId) && !isEmpty(this.state.sendMessage) && window.chatsocket) {
            window.chatsocket.emit(
                'message',
                { courseId: this.props.course.courseId, message: this.state.sendMessage },
                this.getConversationIdCallback,
            );
            this.setState({ sendMessage: '', isBottomCancelScroll: false });
        }
    };

    getConversationIdCallback = (data: any) => {
        const conversationId = data?.data?.newMessage?.chatConversation ?? '';
        if (!data?.success) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: data?.error,
            });
            return;
        }
        if (conversationId && data?.data?.newMessage) {
            this.props.setCourseChat({ ...this.props.courseChat, conversationId: conversationId });
            const cloneMessage = clone(flatMap(this.state.chatMessageList)) ?? [];

            if (isEmpty(cloneMessage)) {
                window.chatsocket?.emit('join-room', { conversationId: conversationId });
                window.chatsocket?.on(conversationId, (data) => {
                    if (data?.newMessage) {
                        this.updateMessageList(this.state.chatMessageList, data.newMessage);
                    }
                });
            }

            cloneMessage.push(data?.data?.newMessage);
            this.setState({ chatMessageList: this.groupMessages(cloneMessage), sendMessage: '' });
        }
    };

    updateMessageList = (messageList: Record<string, ChatMessage[]>, updateMessage: ChatMessage) => {
        const cloneMessage = clone(flatMap(messageList));
        cloneMessage.push(updateMessage);
        this.setState({ chatMessageList: this.groupMessages(cloneMessage) });
    };

    renderMessageList = () => {
        return Object.keys(this.state.chatMessageList).map((key: string) => (
            <div className='message-list-wrapper' key={key}>
                <p className='grouped-key'>{key?.slice(0, 5)}</p>
                {this.state.chatMessageList[key].map((message: ChatMessage, idx: number) => (
                    <MessageContainer
                        key={key + '/' + idx}
                        {...message}
                        currentUserId={this.props.loggedIn?.user?._id ?? ''}
                    />
                ))}
            </div>
        ));
    };

    handleScroll = (event: any) => {
        const target = event.target;
        if (target?.scrollTop === 0) {
            this.loadMoreConversationData();
        }
    };

    loadMoreConversationData = debounce(async () => {
        if (this.state.page === this.state.totalPages) return;
        this.setState({ page: this.state.page + 1, isMoreLoading: true, isBottomCancelScroll: true });

        const { success, response } = await Api.chatCall(
            'GET',
            `/messages/${this.props.courseChat?.conversationId}?page=${this.state.page}`,
        );

        if (success) {
            this.setState({
                isMoreLoading: false,
            });

            const messagesData = [...response.docs].reverse();
            const cloneMessage = clone(flatMap(this.state.chatMessageList));
            cloneMessage.unshift(...messagesData);
            this.setState({
                chatMessageList: this.groupMessages(cloneMessage),
            });
        } else {
            this.setState({
                isMoreLoading: false,
            });
        }
    }, 700);

    preventParentEvent = (e: any) => {
        if (e) {
            e.stopPropagation();
        }
    };

    sendMessageKeyDownWrapper = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        sendMessageKeyDown(event, (message) => this.setState({ sendMessage: message }), this.sendMessageToInstructor);
    };
    render() {
        return (
            <Popover placement='bottom' id='user-bar-chat-menu'>
                <Popover.Body>
                    <Row className='chat-pane-container'>
                        <Col
                            className='chat-message-container'
                            id='chat-message-container'
                            onClick={this.preventParentEvent}
                        >
                            {!this.props.courseChat?.conversationId ? (
                                <div className='blank-message-container'>
                                    <p>
                                        No chat history. <br />
                                        Please initiate the chat
                                    </p>
                                </div>
                            ) : this.state.isLoading ? (
                                <APISpinner />
                            ) : (
                                <div ref={this.containerRef as any} className='chat-message-wrapper'>
                                    <InfiniteScroll
                                        dataLength={21}
                                        next={() => {}}
                                        hasMore={this.state.isMoreLoading}
                                        onScroll={this.handleScroll}
                                        loader={''}
                                        scrollableTarget='chat-message-container'
                                        scrollThreshold={0.8}
                                        className='infinite-scroll-student'
                                    >
                                        {this.state.isMoreLoading && (
                                            <div className='spinner'>
                                                <Spinner
                                                    animation='border'
                                                    role='status'
                                                    style={{
                                                        width: '3.2rem',
                                                        height: '3.2rem',
                                                        borderWidth: '.3rem',
                                                        color: '#2fcca1',
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {this.renderMessageList()}
                                    </InfiniteScroll>
                                </div>
                            )}
                        </Col>
                        <Col className='message-field'>
                            <Row className='message-field-wrapper'>
                                <Col className='input-wrapper'>
                                    <ResizableTextArea
                                        value={this.state.sendMessage}
                                        onChange={this.onHandleSendMessage}
                                        onKeyPress={this.sendMessageKeyDownWrapper}
                                        id={`instructor-message`}
                                        name={`instructor-message`}
                                        classsName='instructor-message'
                                        placeholder='Type message'
                                        onClick={this.preventParentEvent}
                                    />
                                </Col>
                                <Col className='button-wrapper'>
                                    <Button
                                        disabled={this.state.isLoading}
                                        className={`message-submit-button ${
                                            this.state.isLoading || isEmpty(this.state.sendMessage)
                                                ? 'btn--disabled'
                                                : ''
                                        }`}
                                        onClick={this.sendMessageToInstructor}
                                    >
                                        <i className='fa-solid fa-paper-plane' />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Popover.Body>
            </Popover>
        );
    }
}

export default withRouterAndRedux(
    ChatPopOver,
    (state: any) => {
        return { loggedIn: state.loggedIn, courseChat: state.courseChat };
    },
    {
        setCourseChat: (payload: any) => ({
            type: 'SET_CONVERSATION_DATA',
            payload,
        }),
    },
);
