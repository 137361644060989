import React, { Component } from 'react';
import { RouteComponentProps } from 'src/hoc/withRouter';
import { EventBus } from 'src/helpers/new';
import { RootState } from 'src/store/reducers/rootReducer';
import CheckoutContext from '../../../CheckoutContext';
import Next from '../../Next';
import { CheckoutValidateHelper } from '../CheckoutValidateHelper';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import * as Sentry from '@sentry/react';

type TProps = RouteComponentProps & IConnectProps;

interface Course {
    purchaseType: string;
}

interface IConnectProps {
    loggedIn: any;
}

export interface IContact {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    startedAt: string;
    updatedAt: string;
    createdAt: string;
}

interface IState {
    contact: IContact;
    validation: {
        firstNameMessage: string;
        lastNameMessage: string;
        emailMessage: string;
        confirmEmailMessage: string;
    };
}

class Contact extends Component<TProps, IState> {
    static readonly contextType = CheckoutContext;
    context!: React.ContextType<typeof CheckoutContext>;

    constructor(props: TProps, context: any) {
        super(props);
        this.state = {
            contact: { ...context.contact, startedAt: context.contact.startedAt || new Date() },
            validation: {
                firstNameMessage: '',
                lastNameMessage: '',
                emailMessage: '',
                confirmEmailMessage: '',
            },
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        if (this.context.tabs?.[1]?.completed) {
            this.setState((prevState) => ({
                contact: { ...prevState.contact, confirmEmail: this.context.contact.email },
            }));
        }
        EventBus.on('validate-contact-tab', this.validateInputs);
    }

    componentWillUnmount() {
        EventBus.remove('validate-contact-tab', this.validateInputs);
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState((prevState) => {
            const contact = { ...prevState.contact };
            contact[e.target.name as keyof IContact] =
                e.target.name === 'email' ? e.target.value.toLowerCase() : e.target.value;

            this.context.updateCheckoutState({
                contact: contact,
            });

            return { contact };
        });
    };

    hanldeCopyPasteConfiramtionEmail = (e: any) => {
        e.preventDefault();
    };

    validateInputs = async () => {
        try {
            const { proceed, messages } = CheckoutValidateHelper.validateContact(
                this.state.validation,
                this.state.contact,
            );
            const { isAdminPreview } = this.context;
            const userFromLocalStorage = localStorage.getItem('user');
            let userData, hasProctorOrChat;
            if (userFromLocalStorage) {
                userData = JSON.parse(userFromLocalStorage ?? '');
                const courses = this.context.packages.filter((pkg: any) => pkg.hasOwnProperty('purchaseType'));

                hasProctorOrChat = courses.some(
                    (course: Course) =>
                        course.purchaseType === 'proctorPass' ||
                        course.purchaseType === 'chat' ||
                        course.purchaseType === 'extension' ||
                        course.purchaseType === 'repurchase',
                );
            }

            if (proceed || isAdminPreview) {
                if (hasProctorOrChat && userData?.email !== this.state.contact.email && !isAdminPreview) {
                    messages.emailMessage = "You can't change the email for proctor/chat/extension/repurchase!";
                    messages.confirmEmailMessage = '';
                    this.setState({ validation: messages });
                } else {
                    this.context.switchTab(2, true);
                    await this.context.updateCart({
                        contact: {
                            ...this.state.contact,
                            createdAt: this.context.contact.createdAt ?? new Date(),
                            updatedAt: new Date(),
                        },
                        currentTab: 2,
                    });
                    if (window.fbq && typeof window.fbq === 'function' && !isAdminPreview) {
                        window.fbq('track', 'Contact');
                    }
                }
            } else {
                this.setState({
                    validation: messages,
                });
            }

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    redirectLogin = () => {
        localStorage.removeItem('authToken');

        window.location.href = '/login';
    };

    checkedEmail = async () => {
        const { inUse, canUse } = await CheckoutValidateHelper.checkEmail(
            this.state.contact.email,
            this.props.loggedIn?.user?._id ?? '',
        );
        this.setState((prevState) => {
            const messages = { ...prevState.validation };
            if (inUse && !canUse) {
                messages.emailMessage = 'Email Already Registered';
            } else {
                messages.emailMessage = '';
            }

            return { validation: messages };
        });
    };

    render() {
        const { firstName, lastName, email, confirmEmail } = this.state.contact;
        const { firstNameMessage, lastNameMessage, emailMessage, confirmEmailMessage } = this.state.validation;

        return (
            <>
                <div className='checkout-contact'>
                    <h1>Contact info</h1>
                    <h3>{`Please write your name exactly as it appears on your driver's license or ID.`}</h3>
                    <div className='checkout-form'>
                        <div>
                            <label htmlFor='cFirstName'>First name *</label>
                            <input
                                type='text'
                                value={firstName}
                                onChange={this.handleInputChange}
                                name='firstName'
                                id='cFirstName'
                            />
                            <p>{firstNameMessage}</p>
                        </div>
                        <div>
                            <label htmlFor='cLastName'>Last name *</label>
                            <input
                                type='text'
                                value={lastName}
                                onChange={this.handleInputChange}
                                name='lastName'
                                id='cLastName'
                            />
                            <p>{lastNameMessage}</p>
                        </div>
                        <div>
                            <label htmlFor='cEmail'>Email *</label>
                            <input
                                type='email'
                                value={email}
                                onChange={this.handleInputChange}
                                name='email'
                                id='cEmail'
                                onBlur={this.checkedEmail}
                            />
                            <p>{emailMessage}</p>
                            {emailMessage === 'Email Already Registered' ? (
                                <button onClick={this.redirectLogin} className='login-link'>
                                    Login to your account
                                </button>
                            ) : (
                                ''
                            )}
                        </div>

                        <div>
                            <label htmlFor='cConfirmEmail'>Email address confirmation *</label>
                            <input
                                type='email'
                                value={confirmEmail}
                                onChange={this.handleInputChange}
                                onPaste={this.hanldeCopyPasteConfiramtionEmail}
                                name='confirmEmail'
                                id='cConfirmEmail'
                            />
                            <p>{confirmEmailMessage}</p>
                        </div>
                    </div>
                </div>
                <Next onClick={this.validateInputs} />
            </>
        );
    }
}

export default withRouterAndRedux(
    Contact,
    (state: RootState) => {
        return {
            loggedIn: state.loggedIn,
        };
    },
    null,
);
