import React, { Component, ReactNode } from 'react';
import './ProgressBar.scss';
import { isNil } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConditionalWrapper from 'src/components/ConditionalWrapper';

interface IProps {
    progress: number;
    displayProgress?: string;
    passedAt: string;
    isDisplayTooltip?: boolean;
}

export default class ProgressBar extends Component<IProps, unknown> {
    render(): ReactNode {
        const { progress, displayProgress, passedAt, isDisplayTooltip } = this.props;
        const displayStr = progress > 0 && progress < 30 && displayProgress ? `${progress}%` : displayProgress;
        return (
            <div className='progress-bar-container'>
                <ConditionalWrapper
                    condition={isDisplayTooltip && displayProgress}
                    wrapper={(children: any) => (
                        <OverlayTrigger overlay={<Tooltip id={`tooltip-next-lesson`}>{displayProgress}</Tooltip>}>
                            {children}
                        </OverlayTrigger>
                    )}
                >
                    <div className={`bar ${progress === 0 || isNil(progress) ? 'progress-less-zero' : ''}`}>
                        {progress !== 0 && !isNil(progress) ? (
                            <div
                                className={`progress ${!isNil(progress) && progress < 13 ? 'progress_spacing' : ''}`}
                                style={{ width: `${progress}%` }}
                            >
                                {passedAt ? `Completed on ${passedAt}` : displayStr ? displayStr : `${progress}%`}
                            </div>
                        ) : displayStr ? (
                            displayStr
                        ) : (
                            '0%'
                        )}
                    </div>
                </ConditionalWrapper>
            </div>
        );
    }
}
