import './Main.scss';
import React, { Component } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import UserBar from './UserBar/UserBar';
import { Alert, Spinner } from 'react-bootstrap';
import { io } from 'socket.io-client';
import { Api, EventBus } from 'src/helpers/new';
import ExpirePasswordPrompt from 'src/pages/Account/ExpirePwdPrompt/ExpirePasswordPrompt';
import ChangePasswordPrompt from 'src/pages/Account/ChangePasswordPrompt/ChangePasswordPrompt';
import MaintenancePrompt from 'src/pages/Account/MaintenancePrompt/MaintenancePrompt';
import { clearState, getState } from 'src/helpers/localStorage';
import { isEmpty, isNil, set } from 'lodash';
import AnnouncemntToast from 'src/components/Announcement/AnnouncemntToast';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import ExamBanner from 'src/pages/Home/ExamBanner/ExamBanner';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import * as Sentry from '@sentry/react';

class Main extends Component {
    state = {
        loading: true,
        globalAlert: null,
        showGlobalAlert: false,
        verifyEmailAlert: false,
        notificationCount: 0,
        isScrollHidden: false,
        showChangePassword: false,
        isLoginToken: false,
        isShowMaintenancePage: false,
        isShowExamBanner: null,
        isShowExamSpace: false,
    };

    regexPublicUrls = [
        /^\/login$/,
        /^\/reset-password$/,
        /^\/change-password$/,
        /^\/terms_of_service$/,
        /^\/privacy_policy$/,
        /^\/checkout/,
        /^\/unsubscribe-notifications/,
        /^\/status$/,
    ];

    isAuthorizedRoute = () => {
        return !this.regexPublicUrls.some((regex) => regex.test(this.props.location.pathname));
    };

    addNotificationCountReceivedSocketEvent = () => {
        window.socket.on('notification-count-received', (notificationCount) => {
            this.setState({
                notificationCount: notificationCount || 0,
            });
        });
    };

    captureMaitenanceEvent = () => {
        window.socket.on('maintenance-settings-updated', this.maintenanceEvent);
    };

    async componentDidMount() {
        EventBus.on('toggle-main-scroll', this.toggleScroll);
        EventBus.on('add-notification-socket-event', this.addNotificationCountReceivedSocketEvent);
        EventBus.on('change-password-from-checkout-event', this.changePasswordShow);
        EventBus.on('show-maintenance-screen-prompt', this.showMaintenancePrompt);
        EventBus.on('show-exam-banner', this.showExamBanner);
        EventBus.on('process-after-checkout', this.processAfterCheckout);

        const token = localStorage.getItem('authToken');

        if (token && !this.props.loggedIn.token) {
            const { success, response } = await Api.call('POST', '/users/auth', null, false, token);
            this.unsetGlobalAlertTimeout = null;
            if (success) {
                this.props.setLoggedIn(response);
                localStorage.setItem('authToken', response.token);
                window.socket = io(process.env.REACT_APP_WS_URL, {
                    query: {
                        token,
                    },
                    secure: process.env.ENV_NAME !== 'development',
                    transports: ['websocket'],
                });

                window.socket.on('connect', () => {
                    this.captureMaitenanceEvent();
                });
            } else {
                window.socket = io(process.env.REACT_APP_WS_URL, {
                    secure: process.env.ENV_NAME !== 'development',
                    transports: ['websocket'],
                });

                window.socket.on('connect', () => {
                    this.captureMaitenanceEvent();
                });
            }

            if (!isEmpty(response.user)) {
                window.socket.on('connect', () => {
                    this.addNotificationCountReceivedSocketEvent();
                    window.socket.emit('get-notification-count', response.user._id);
                });

                if (response.user.isUsingLoginToken) {
                    this.setState({ showChangePassword: true });
                }

                if (this.isShowMaintenancePage(response)) {
                    this.setState({ isShowMaintenancePage: true });
                }
            }
        } else if (!token && !this.props.loggedIn.token) {
            window.socket = io(process.env.REACT_APP_WS_URL, {
                secure: process.env.ENV_NAME !== 'development',
                transports: ['websocket'],
            });

            window.socket.on('connect', () => {
                this.captureMaitenanceEvent();
                this.addNotificationCountReceivedSocketEvent();
            });

            const { success, response } = await Api.call('GET', '/settings/maintenance');
            if (success) {
                if (!isEmpty(response)) {
                    this.props.setLoggedIn({
                        maintenance: response,
                    });
                    if (response.startTime && response.endTime) {
                        if (
                            DateTimeFormatHelper.isBetween(
                                DateTimeFormatHelper.currentDate(),
                                DateTimeFormatHelper.getDate(response.startTime),
                                DateTimeFormatHelper.getDate(response.endTime),
                                null,
                                '[]',
                            )
                        ) {
                            this.setState({ isShowMaintenancePage: true });
                        }
                    }
                }
            }
        }
        this.setState({
            loading: false,
        });

        if (this.checkisLoginToken() && !this.state.isLoginToken) {
            const query = new URLSearchParams(this.props.location.search);
            await this.processOperationAfterCheckout(query.get('loginToken'), query.get('email'));
        }
    }

    componentWillUnmount() {
        EventBus.remove('toggle-main-scroll', this.toggleScroll);
        EventBus.remove('add-notification-socket-event', this.addNotificationCountReceivedSocketEvent);
        EventBus.remove('change-password-from-checkout-event', this.changePasswordShow);
        EventBus.remove('show-maintenance-screen-prompt', this.showMaintenancePrompt);
        EventBus.remove('show-exam-banner', this.showExamBanner);
        EventBus.remove('process-after-checkout', this.processAfterCheckout);
    }

    toggleScroll = (value) => {
        this.setState({
            isScrollHidden: !!value.detail,
        });
    };

    dismissVerifyEmail = () => {
        this.setState({
            verifyEmailAlert: false,
        });
    };

    showExamBanner = (event) => {
        const { isResume } = event.detail;
        this.setState({
            isShowExamBanner: isNil(isResume) ? null : isResume,
        });
    };

    resendEmail = async () => {
        await Api.call('GET', '/users/verify/resend');
    };

    updateGlobalAlert = (globalAlert) => {
        this.setState(
            {
                globalAlert: globalAlert,
            },
            () => {
                this.setState({
                    showGlobalAlert: true,
                });

                setTimeout(() => {
                    this.setState(
                        {
                            showGlobalAlert: false,
                        },
                        () => {
                            this.unsetGlobalAlertTimeout = setTimeout(() => {
                                this.props.unsetGlobalAlert();
                            }, 250);
                        },
                    );
                }, 2750);
            },
        );
    };

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !!this.props.globalAlert &&
            !!this.props.globalAlert.message &&
            (!this.state.globalAlert || this.state.globalAlert.ts !== this.props.globalAlert.ts)
        ) {
            const currentTs = this.state.globalAlert && this.state.globalAlert.ts ? this.state.globalAlert.ts : 0,
                tsDiff = this.props.globalAlert.ts - currentTs;
            if (tsDiff < 2000) {
                if (this.unsetGlobalAlertTimeout) {
                    clearTimeout(this.unsetGlobalAlertTimeout);
                }

                setTimeout(() => {
                    this.updateGlobalAlert(this.props.globalAlert);
                }, tsDiff);
            } else {
                this.updateGlobalAlert(this.props.globalAlert);
            }
        }
    }

    processAfterCheckout = async (event) => {
        try {
            const { loginToken, email } = event.detail;
            await this.processOperationAfterCheckout(loginToken, email);
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    processOperationAfterCheckout = async (loginToken, email) => {
        try {
            if (loginToken) {
                if (loginToken === 'ext') {
                    if (this.props.loggedIn.token && this.props.loggedIn.user.email === email) {
                        this.props.navigate('/');
                    } else {
                        await this.autoLogoutRedirect();
                    }
                    this.setState({ isLoginToken: true });
                } else {
                    const { success, response: tokenResponse } = await Api.call('GET', `/users/token/${loginToken}`);

                    if (success && tokenResponse) {
                        window.history.replaceState(null, '', this.props.match.pathname);
                        await this.autoLogin(tokenResponse);
                    } else {
                        if (this.props.loggedIn.token) {
                            window.history.replaceState(null, '', this.props.match.pathname);
                        } else {
                            this.props.navigate('/login');
                        }
                        this.setState({ isLoginToken: true });
                    }
                }
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    autoLogoutRedirect = async () => {
        if (getState('authToken')) {
            const { success, message } = await Api.call('POST', '/users/logout');
            if (success) {
                this.handleLogout(message);
            }
        } else {
            this.handleLogout('');
        }
    };

    handleLogout = (message) => {
        if (!isEmpty(this.props.loggedIn)) {
            EventBus.dispatch('toast', {
                type: 'success',
                message: !!message ? message : "You've been successfully logged out.",
            });
            clearState();
            this.props.setLoggedIn({
                token: null,
                user: null,
            });
            EventBus.dispatch('show-exam-banner', { isResume: null });
        }

        window.socket.emit('logout');
        if (window.chatsocket) {
            window.chatsocket.close();
            set(window, 'chatsocket', undefined);
        }
        this.props.navigate('/login');
    };

    autoLogin = async (tokenResponse) => {
        const { response, success, message } = await Api.call('POST', '/users/login', {
            email: tokenResponse.user.email,
            browserAgent: navigator.userAgent,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            isAfterCheckout: true,
        });

        if (success) {
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('authToken', response.token);

            this.props.setLoggedIn(response);
            EventBus.dispatch('change-password-from-checkout-event', response.user.isUsingLoginToken);
            if (process.env.REACT_APP_WS_URL) {
                window.socket = io(process.env.REACT_APP_WS_URL, {
                    query: {
                        token: response.token,
                    },
                    transports: ['websocket'],
                });

                window.socket.on('connect', () => {
                    this.captureMaitenanceEvent();
                    EventBus.dispatch('add-notification-socket-event');
                    window.socket.emit('get-notification-count', response.user._id);
                });
            }
            if (this.isShowMaintenancePage(response)) {
                EventBus.dispatch('show-maintenance-screen-prompt', true);
            }
        } else {
            EventBus.dispatch('toast', {
                type: 'error',
                message: message,
            });
        }
        this.setState({ isLoginToken: true });
    };

    loggedInUserHasExpiredPassword = () => {
        if (
            this.isAuthorizedRoute() &&
            this.props.loggedIn.token &&
            (this.props.loggedIn.user?.passwordExpiredAt === null
                ? false
                : this.props.loggedIn.user.passwordExpiredAt < new Date().toISOString())
        ) {
            return true;
        } else {
            return false;
        }
    };

    isShowMaintenancePage = (value) => {
        const response = value ? value : this.props.loggedIn;
        if (!isEmpty(response?.user?.maintenance)) {
            const maintenance = response?.user.maintenance;
            if (maintenance.startTime && maintenance.endTime) {
                if (
                    DateTimeFormatHelper.isBetween(
                        DateTimeFormatHelper.currentDate(),
                        DateTimeFormatHelper.getDate(maintenance.startTime),
                        DateTimeFormatHelper.getDate(maintenance.endTime),
                        null,
                        '[]',
                    )
                ) {
                    return true;
                }
            } else {
                return false;
            }
        }

        return false;
    };

    onChangePassowrd = (closeModal = false) => {
        if (!this.props?.loggedIn?.user?.isUsingLoginToken || closeModal) {
            this.setState({
                showChangePassword: false,
            });
        }
    };

    onChangeShowMaintenancePage = () => {
        this.setState({
            isShowMaintenancePage: false,
        });
    };

    changePasswordShow = (value) => {
        this.setState({
            showChangePassword: value,
        });
    };

    showMaintenancePrompt = (value) => {
        this.setState({
            isShowMaintenancePage: value,
        });
    };

    onLogoClicked = () => {
        if (this.isShowMaintenancePage()) {
            this.showMaintenancePrompt(true);
        }
    };

    maintenanceEvent = async (data) => {
        if (!isEmpty(data)) {
            if (data?.maintenance?.startTime && data?.maintenance?.endTime) {
                if (
                    DateTimeFormatHelper.isBetween(
                        DateTimeFormatHelper.currentDate(),
                        DateTimeFormatHelper.getDate(data?.maintenance?.startTime),
                        DateTimeFormatHelper.getDate(data?.maintenance?.endTime),
                        null,
                        '[]',
                    )
                ) {
                    window.location.reload(true);
                } else if (this.state.isShowMaintenancePage) {
                    window.location.reload(true);
                }
            } else if (this.state.isShowMaintenancePage) {
                window.location.reload(true);
            }
        }
    };

    checkisLoginToken = () => {
        const loginToken = this.props.location.search.slice(this.props.location.search.indexOf('?') + 1).split('&');
        const query = new URLSearchParams(this.props.location.search);

        return this.props.location.pathname === '/' && loginToken?.length === 2 && query.get('loginToken');
    };

    isToAddPrefClass = () => {
        const token = localStorage.getItem('authToken');
        return !token && [/^\/unsubscribe-notifications/].some((regex) => regex.test(this.props.location.pathname))
            ? true
            : false;
    };

    getExamBanner = () => {
        return (
            (this.props.location.pathname === '/' && this.state.isShowExamBanner) || isNil(this.state.isShowExamBanner)
        );
    };

    render() {
        let mainContent;
        if (this.state.loading) {
            mainContent = (
                <div className='center-loading big'>
                    <Spinner animation='border' />
                </div>
            );
        } else if (this.checkisLoginToken() && !this.state.isLoginToken) {
            mainContent = (
                <div className='center-loading big'>
                    <Spinner animation='border' />
                </div>
            );
        } else {
            if (!this.props.loggedIn.token && this.isAuthorizedRoute()) {
                return (
                    <Navigate
                        to={{
                            pathname: '/login',
                            state: {
                                previousPath: this.props.location.pathname,
                            },
                        }}
                    />
                );
            } else if (this.loggedInUserHasExpiredPassword()) {
                mainContent = <ExpirePasswordPrompt />;
            } else {
                mainContent = (
                    <>
                        {this.isAuthorizedRoute() && <AnnouncemntToast />}
                        <Outlet />
                    </>
                );
            }
        }

        return (
            <div className={`wrapper ${!this.isToAddPrefClass() ? '' : 'pref'}`}>
                <header>
                    <Link to='/' onClick={this.onLogoClicked}>
                        <img
                            className='logo-img'
                            src={!this.isToAddPrefClass() ? '/logo.png' : '/logo-white.png'}
                            alt='RealEstateU logo'
                        />
                    </Link>
                    <UserBar
                        notificationCount={this.state.notificationCount}
                        isUsingLoginToken={this.props.loggedIn?.user?.isUsingLoginToken}
                        isShowMaintenancePage={this.isShowMaintenancePage()}
                    />
                </header>
                {this.getExamBanner() && <ExamBanner isShowExamBanner={this.state.isShowExamBanner} />}
                {this.state.verifyEmailAlert && !!this.props.loggedIn.user && !this.props.loggedIn?.user?.verified && (
                    <div className='verify-email'>
                        <Alert variant='danger' onClose={this.dismissVerifyEmail} dismissible>
                            <Alert.Heading>Verify your e-mail address</Alert.Heading>
                            <p>
                                We have sent an validation link to the address provided by you in the registration
                                process to confirm your e-mail address. Did you not get any e-mail? Be sure to check
                                spam or junk folders or&nbsp;
                                <b onClick={this.resendEmail}>click here to resend e-mail verification.</b>
                            </p>
                        </Alert>
                    </div>
                )}
                <main
                    className={`${this.state.isScrollHidden ? 'hidden' : ''} ${
                        this.props.location.pathname == '/' && this.state.isShowExamBanner == true
                            ? 'main-header-height-with-exam-banner'
                            : ''
                    }`}
                >
                    {mainContent}
                </main>
                {this.state.globalAlert && (
                    <div
                        className={
                            `global-alert global-alert--${this.state.globalAlert.type}` +
                            (this.state.showGlobalAlert ? ' global-alert--visible' : '')
                        }
                    >
                        {this.state.globalAlert.type === 'success' && <Fa icon={faCheckCircle} />}
                        {this.state.globalAlert.type === 'error' && <Fa icon={faTimesCircle} />}
                        <span
                            className='message'
                            dangerouslySetInnerHTML={{
                                __html: this.state.globalAlert.message,
                            }}
                        ></span>
                    </div>
                )}
                <ChangePasswordPrompt onChangePassowrd={this.onChangePassowrd} isShow={this.state.showChangePassword} />
                <MaintenancePrompt
                    isShow={this.state.isShowMaintenancePage}
                    onChangeShowMaintenancePage={this.onChangeShowMaintenancePage}
                />
            </div>
        );
    }
}

export default withRouterAndRedux(Main, (state) => ({ loggedIn: state.loggedIn, globalAlert: state.globalAlert }), {
    setLoggedIn: (payload) => ({
        type: 'SET_LOGGED_IN',
        payload,
    }),
    unsetGlobalAlert: () => ({
        type: 'UNSET_GLOBAL_ALERT',
    }),
});
